import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import FAQ from "components/faqs/SingleCol.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "About My Vehicle Reports" }) => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>About My Vehicle Reports</h1>
            <p>
              My Vehicle Reports is the premier vehicle history data company offering powerful tools to individuals and companies who need automobile information at their fingertips. Our mission is to provide reliable and comprehensive vehicle history reports to help our customers make informed decisions when buying, selling, or maintaining a vehicle.
            </p>
            <h2>Our Commitment to Transparency</h2>
            <p>
              At My Vehicle Reports, we believe that access to accurate vehicle information should be readily available to everyone. That's why we've developed a user-friendly platform that allows our customers to quickly and easily access detailed vehicle history reports, regardless of the make, model, or year of the vehicle.
            </p>
            <h2>Comprehensive Vehicle History Reports</h2>
            <p>
              Our vehicle history reports provide a comprehensive overview of a vehicle's past, including details on title history, accident records, recalls, and much more. We source our data from a variety of trusted industry and government sources, ensuring that our customers have the most up-to-date and reliable information at their fingertips.
            </p>
            <h2>Empowering Informed Decisions</h2>
            <p>
              By offering free VIN checks and detailed vehicle history reports, My Vehicle Reports empowers our customers to make informed decisions when buying, selling, or maintaining a vehicle. We believe that knowledge is power, and we're committed to providing our customers with the tools and resources they need to make the best choices for their needs.
            </p>
            <h2>Our Team</h2>
            <p>
              My Vehicle Reports is led by a team of experienced professionals who are passionate about the automotive industry. Our staff includes data analysts, software engineers, and customer service experts who work tirelessly to ensure that our customers have the best possible experience.
            </p>
            <h2>Contact Us</h2>
            <p>
              If you have any questions or would like to learn more about our services, please don't hesitate to contact us. You can reach us by phone at (800) 764-0104 or by email at <a href="mailto:Info@myvehiclereports.com" >Info@myvehiclereports.com</a>.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Do you have <HighlightedText>Questions?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is a VIN history report?",
            answer:
              "A VIN history report provides detailed information about a vehicle's past, including accidents, title changes, and ownership history. This helps you make an informed decision when purchasing a used car."
          },
          {
            question: "How can I get a VIN history report?",
            answer:
              "Simply enter the Vehicle Identification Number (VIN) of the car you are interested in on our website, and you will receive a detailed report instantly."
          },
          {
            question: "Why is a VIN history report important?",
            answer:
              "A VIN history report is crucial for understanding the true condition and history of a used vehicle. It helps you avoid cars with hidden issues, ensuring a safer and smarter purchase."
          },
          {
            question: "What information is included in a VIN history report?",
            answer:
              "Our VIN history reports include accident history, title information, odometer readings, ownership details, and more. This comprehensive data gives you a clear picture of the vehicle's past."
          },
          {
            question: "Can I check the history of any vehicle using a VIN?",
            answer:
              "Yes, you can check the history of any vehicle with a valid VIN, including cars, trucks, motorcycles, and more."
          },
          {
            question: "Are payments refundable?",
            answer:
              "Please note that all payments for our vehicle history reports are non-refundable. We encourage you to review the sample report before making a purchase."
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
