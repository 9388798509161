// src/components/VehicleHistory.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
const CryptoJS = require('crypto-js');

const secretKey = "imareesha1412hosiyarinhinhihopayegatmse14"; // Use the same key as in the React app

const decryptVin = (encryptedVin) => {
  const base64Ciphertext = encryptedVin.replace(/-/g, '+').replace(/_/g, '/');
  const ciphertext = atob(base64Ciphertext);
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};


const VehicleHistory = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(false);
  const { vin } = useParams(); // Assuming your route is like /vinreport/:vin
  const decryptedVinNumber = decryptVin(decodeURIComponent( vin ));

  // Use the extracted VIN number
  const carVinNumber = decryptedVinNumber; // This will hold the VIN from the URL
  const value = {
    "status": "success",
    "data": {
        "vin": "YV4992DZ3A2101263",
        "year": "2010",
        "make": "Volvo",
        "model": "XC60",
        "html": "<!DOCTYPE html><html lang='en'>  <head>    <meta charset='utf-8' />    <meta .."

    }
};
// Function to change the color of the response element


  const fetchHtmlContent = () => {
    const vincheck = localStorage.getItem("vin");
    const decryptedVinNumber = decryptVin(vin);
    console.log(vincheck);



    if(decryptedVinNumber  === vincheck){
      console.log("Great Job dude..")
    }
    else{
      console.log("Insan tm banoge ya mein banao...");
      alert("Don't try to crack our website")
    }

      setLoading(true);
      setHtmlContent(value.data.html);
      setLoading(false);

  };
  useEffect(() => {
    fetchHtmlContent();
  }, []);

  const downloadPdf = () => {
    axios.post('http://localhost:3000/convert', { html: htmlContent }, {
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf'); // or whatever you want the file name to be
      document.body.appendChild(link);
      link.click();
    }).catch(error => {
      console.error('Error downloading the PDF:', error);
    });
  };
  return (
    <div className="VehicleHistory">
      <button onClick={downloadPdf} disabled={!htmlContent}>
        Download PDF
      </button>
      <div id="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default VehicleHistory;
