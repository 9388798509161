import React from "react";
import tw from "twin.macro";
import mockupImageSrc from "images/Demo1.png"
import mockupImageSrc2 from "images/Demo2.png"
import mockupImageSrc3 from "images/Demo3.png"
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts";


const Container = tw.div`relative`;
const Row = tw.div`mt-20 px-8 flex items-center relative z-10 flex-col lg:flex-row text-center justify-center`;

const ColumnContainer = tw.div`max-w-2xl`

const ImageContainer = tw(ColumnContainer)`mt-5 lg:mt-0 flex justify-end`;
const StepText = tw.div`uppercase tracking-widest font-bold text-primary-100 inline text-3xl`;
const Text = tw.div`text-primary-500 inline mr-2 text-2xl`; // Adjust the font size and spacing
const HeadingText = tw.div`text-primary-500 mt-5 mb-20 text-4xl  tracking-widest font-bold `;
export default ({
  imageSrc = mockupImageSrc,
  imageSrc2 = mockupImageSrc2,
  imageSrc3 = mockupImageSrc3,
}) => {
  return (
    <Container >
        <Row style={{justifyContent:"space-around"}}>
          <div>
          <Text> Step <StepText>01</StepText></Text>
          <ImageContainer>
            <img src={imageSrc} alt="" tw="w-64"/>
          </ImageContainer>
          <HeadingText> Search </HeadingText>
          </div>
         
          <div>
          <Text> Step <StepText>02</StepText></Text>
          <ImageContainer>
            <img src={imageSrc2} alt="" tw="w-64"/>
          </ImageContainer>
          <HeadingText> Preview </HeadingText>
          </div>
          <div>
          <Text> Step <StepText>03</StepText></Text>
          <ImageContainer>
            <img src={imageSrc3} alt="" tw="w-64"/>
          </ImageContainer>
          <HeadingText> Download </HeadingText>
          </div>
        </Row>
     
    </Container>
  );
};
