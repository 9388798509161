// TawkToChat.js
import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    const s1 = document.createElement('script');
    s1.async = true;
    s1.src='https://embed.tawk.to/66a3e82c32dca6db2cb647e2/1i3o45rje';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    const s0 = document.getElementsByTagName('script')[0];
    s0.parentNode.insertBefore(s1, s0);

    // Cleanup script when component unmounts
    return () => {
      s1.parentNode.removeChild(s1);
    };
  }, []);

  return null;
};

export default TawkToChat;