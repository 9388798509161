import React from "react";
import logo from "../../images/logo.svg";
import Speedo from "../../images/speedometer-svgrepo-com.svg";
import Accident from "../../images/car-collision-svgrepo-com.svg";
import TitleRecord from "../../images/record-svgrepo-com.svg";
import TotLossRec from "../../images/car-crash-svgrepo-com.svg";
import ProblemCheck from "../../images/engine-motor-svgrepo-com.svg";
import Junk from "../../images/car-crash-svgrepo-com (1).svg"
import AutoSpecs from "../../images/settings-gear-svgrepo-com.svg";
import recallIcon from "../../images/warning-alert-svgrepo-com.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCalendarCheck,
  faChartSimple,
  faDroplet,
  faGear,
  faGears,
  faImage,
  faMapLocationDot,
  faScrewdriverWrench,
  faTriangleExclamation,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import "./CheckReport.css";


const CustomReport = ( vehicleData ) => {
  console.log(vehicleData);
  vehicleData = vehicleData.vehicleData;
  const recalls = vehicleData.content.Recalls || "";
  const complains = vehicleData.content.RecallsConsumerComplaints || "";
  console.log(vehicleData);
  const isBulletPointData = (value) => {
    return value.includes('|');
  };
  const sortedRecalls = Object.values(recalls).sort((a, b) => {
    return new Date(a.ODATE) - new Date(b.ODATE); // Sort by ODATE field
  });

  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    // Create a Date object
    const date = new Date(`${year}-${month}-${day}`);

    // Options for formatting
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    // Format the date
    return date.toLocaleDateString(undefined, options);
  };
  const { manufacturer } = vehicleData?.content.CarInfo || {};
  const junkAndSalvage = vehicleData?.content.AamvaNmvtis.junkAndSalvage || [];
  const insurers = vehicleData?.content.AamvaNmvtis.insurers || [];
  const titleRecCurr = vehicleData?.content.AamvaNmvtis.titles.current || [];
  const getValue = (data, key) => {
    return data?.[key] || 'N/A';
  };
  const getValue2 = (data, key) => {
    if (data && data.length > 0) {
      const value = data[0][key] || 'N/A';
      return typeof value === 'string' ? value.replace(/^0+/, '') : value;
    }
    return 'N/A';
  };

  const MileageDatafields = [
    { label: "Last Reported Mileage", key: "vehicleOdometerReadingMeasure" },
    { label: "Estimated Mileage", key: "estimatedMileage" },
  ];
  const cardData = [
    { imgSrc: Speedo, title: "Mileage", subtitle: `${getValue2(vehicleData?.content.AamvaNmvtis.titles.current, "vehicleOdometerReadingMeasure")} miles` },
    { imgSrc: Accident, title: "Accident", subtitle: "0 record found" },
    { imgSrc: TitleRecord, title: "Title Records", subtitle: "1 record found" }, //make logic
    { imgSrc: TotLossRec, title: "Junk/Salvage Records", subtitle: `${insurers.length} record found` },
    { imgSrc: Junk, title: "Total Loss Record", subtitle: `${junkAndSalvage.length} record found` },
    { imgSrc: ProblemCheck, title: "Problem Check", subtitle: "0 record found" },
    { imgSrc: AutoSpecs, title: "Auto Specs", subtitle: "1 record found" },
    { imgSrc: recallIcon, title: "NHTSA Recalls", subtitle: `${sortedRecalls.length} record found` },
  ];

  const VehicleDatafields = [
    { label: "Year", key: "Model_Year" },
    { label: "Make, Model", key: "Make" },
    { label: "Trim", key: "Trim_Level" },
    { label: "Drive Type", key: "Driveline" },
    { label: "Brake System", key: "Anti-Brake System" }, // Handle spaces in key
    { label: "Manufactured in", key: "Manufactured in" },
    { label: "Style", key: "Body Style" },
    { label: "Production Seq. Number", key: "Production Seq. Number" }
  ];



  const titrecDatafields = [
    { label: "Location", key: "vehicleOdometerReadingMeasure" },
    { label: "Last odometer reading", key: "vehicleOdometerReadingMeasure" },
    { label: "Purchased in", key: "estimatedMileage" },
    { label: "Was used", key: "estimatedMileage" },

  ];
  //Vindecoder
  const VitalDatafields = [
    { label: "Manufactured in", key: "Manufactured in" },
    { label: "Body Style", key: "Body Style" },
    { label: "Engine Type", key: "Engine Type" },
    { label: "Transmission-long", key: "Transmission-long" },
    { label: "Driveline", key: "Driveline" },
    { label: "Tank", key: "Tank" },
    { label: "Fuel Economy-city", key: "Fuel Economy-city" },
    { label: "Fuel Economy-highway", key: "Fuel Economy-highway" }

  ];
  const VehspecsDatafields = [
    { label: "Anti-Brake System", key: "Anti-Brake System" },
    { label: "Steering Type", key: "Steering Type" },
    { label: "Front Brake Type", key: "Front Brake Type" },
    { label: "Rear Brake Type", key: "Rear Brake Type" },
    { label: "Turning Diameter", key: "Turning Diameter" },
    { label: "Front Suspension", key: "Front Suspension" },
    { label: "Rear Suspension", key: "Rear Suspension" },
    { label: "Front Spring Type", key: "Front Spring Type" },
    { label: "Rear Spring Type", key: "Rear Spring Type" }
  ];


  const MeasureandColor = [
    { label: "Front Headroom", key: "Front Headroom" },
    { label: "Rear Headroom", key: "Rear Headroom" },
    { label: "Front Legroom", key: "Front Legroom" },
    { label: "Rear Legroom", key: "Rear Legroom" },
    { label: "Front Shoulder Room", key: "Front Shoulder Room" },
    { label: "Rear Shoulder Room", key: "Rear Shoulder Room" },
    { label: "Interior Trim", key: "Interior Trim" },
    { label: "Exterior Color", key: "Exterior Color" },
    { label: "Curb Weight-automatic", key: "Curb Weight-automatic" },
    { label: "Wheelbase", key: "Wheelbase" },
    { label: "Ground Clearance", key: "Ground Clearance" },
    { label: "Track Front", key: "Track Front" },
    { label: "Track Rear", key: "Track Rear" },
    { label: "Cargo Length", key: "Cargo Length" },
    { label: "Depth", key: "Depth" },
    { label: "Standard Seating", key: "Standard Seating" },
    { label: "Standard Towing", key: "Standard Towing" },
    { label: "Maximum Towing", key: "Maximum Towing" }
  ];

  const WarrantiesDataField = [
    { label: "Basic-duration", key: "Basic-duration" },
    { label: "Basic-distance", key: "Basic-distance" },
    { label: "Powertrain-duration", key: "Powertrain-duration" },

    { label: "Powertrain-distance", key: "Powertrain-distance" },
    { label: "Rust-duration", key: "Rust-duration" },
    { label: "Rust-distance", key: "Rust-distance" }
  ];


  const SASDataField = [
    { label: "Child Safety Door Locks", key: "Child Safety Door Locks" },
    { label: "Power Door Locks", key: "Power Door Locks" },
    { label: "Vehicle Anti-Theft", key: "Vehicle Anti-Theft" },
    { label: "ABS Brakes", key: "ABS Brakes" },
    { label: "Electronic Brake Assistance", key: "Electronic Brake Assistance" },
    { label: "Traction Control", key: "Traction Control" },
    { label: "Vehicle Stability Control System", key: "Vehicle Stability Control System" },
    { label: "Driver Airbag", key: "Driver Airbag" },
    { label: "Front Side Airbag", key: "Front Side Airbag" },
    { label: "Passenger Airbag", key: "Passenger Airbag" },
    { label: "Side Head Curtain Airbag", key: "Side Head Curtain Airbag" },
    { label: "Electronic Parking Aid", key: "Electronic Parking Aid" },
    { label: "First Aid Kit", key: "First Aid Kit" }
  ];


  const ComfortDataField = [
    { label: "Keyless Entry", key: "Keyless Entry" },
    { label: "Air Conditioning", key: "Air Conditioning" },
    { label: "Cruise Control", key: "Cruise Control" },
    { label: "Tachometer", key: "Tachometer" },
    { label: "Tilt Steering", key: "Tilt Steering" },
    { label: "Tilt Steering Column", key: "Tilt Steering Column" },
    { label: "Steering Wheel Mounted Controls", key: "Steering Wheel Mounted Controls" },
    { label: "Telescopic Steering Column", key: "Telescopic Steering Column" },
    { label: "Genuine Wood Trim", key: "Genuine Wood Trim" },
    { label: "Tire Pressure Monitor", key: "Tire Pressure Monitor" },
    { label: "Driver Multi-Adjustable Power Seat", key: "Driver Multi-Adjustable Power Seat" },
    { label: "Front Heated Seat", key: "Front Heated Seat" },
    { label: "Front Power Memory Seat", key: "Front Power Memory Seat" },
    { label: "Leather Seat", key: "Leather Seat" },
    { label: "Passenger Multi-Adjustable Power Seat", key: "Passenger Multi-Adjustable Power Seat" },
    { label: "Second Row Folding Seat", key: "Second Row Folding Seat" },
    { label: "Second Row Heated Seat", key: "Second Row Heated Seat" },
    { label: "Cargo Area Cover", key: "Cargo Area Cover" },
    { label: "Cargo Area Tiedowns", key: "Cargo Area Tiedowns" },
    { label: "Cargo Net", key: "Cargo Net" },
    { label: "Load Bearing Exterior Rack", key: "Load Bearing Exterior Rack" },
    { label: "Power Sunroof", key: "Power Sunroof" },
    { label: "Manual Sunroof", key: "Manual Sunroof" },
    { label: "Daytime Running Lights", key: "Daytime Running Lights" },
    { label: "High Intensity Discharge Headlights", key: "High Intensity Discharge Headlights" },
    { label: "Running Boards", key: "Running Boards" },
    { label: "Front Air Dam", key: "Front Air Dam" },
    { label: "Rear Spoiler", key: "Rear Spoiler" },
    { label: "Skid Plate", key: "Skid Plate" },
    { label: "Power Trunk Lid", key: "Power Trunk Lid" },
    { label: "Chrome Wheels", key: "Chrome Wheels" },
    { label: "Power Windows", key: "Power Windows" },
    { label: "Heated Exterior Mirror", key: "Heated Exterior Mirror" },
    { label: "Power Adjustable Exterior Mirror", key: "Power Adjustable Exterior Mirror" },
    { label: "Interval Wipers", key: "Interval Wipers" },
    { label: "Rain Sensing Wipers", key: "Rain Sensing Wipers" },
    { label: "Rear Window Defogger", key: "Rear Window Defogger" },
    { label: "Rear Wiper", key: "Rear Wiper" },
    { label: "Tow Hitch Receiver", key: "Tow Hitch Receiver" }
  ];


  const histrecDatafieldsDatafields = [

    { label: "Issuing Authority Name", key: "titleIssuingAuthorityName" },
    { label: "Issue Date", key: "titleIssueDate" },
    { label: "Mileage", key: "vehicleOdometerReadingMeasure" },
    { label: "Mileage Measure Code", key: "vehicleOdometerReadingUnitCode" },

  ]

  const EntertainmentDatafields = [
    { label: "Trip Computer", key: "Trip Computer" },
    { label: "CD Player", key: "CD Player" },
    { label: "DVD Player", key: "DVD Player" },
    { label: "Navigation Aid", key: "Navigation Aid" },
    { label: "Second Row Sound Controls", key: "Second Row Sound Controls" }
  ];

  const titrecDatafieldsDatafields = [
    { label: "Record Match Sequence ID", key: "recordMatchSequenceID" },
    { label: "Issuing Authority Name", key: "titleIssuingAuthorityName" },
    { label: "Issue Date", key: "titleIssueDate" },
    { label: "Mileage", key: "vehicleOdometerReadingMeasure" },
    { label: "Mileage Measure Code", key: "vehicleOdometerReadingUnitCode" },

  ]



  return (
    <>
      <div className="container d-flex justify-content-center  align-items-center ">
        <div className="row w-100 ">
        <div className="d-flex justify-content-between align-items-center">
  <div className="d-flex align-items-center gap-3">
    <div className="Log">
      <img src={logo} alt="logo" style={{ height: "150px" }} />
    </div>
    <div className="Log">
      <h1 className="text-prim fs-1 fw-semibold">My Vehicle Reports</h1>
    </div>
  </div>
  <div className="text-end">
    <p className="fs-5">Date: {new Date().toLocaleDateString()}</p>
  </div>
</div>

<div className="my-3">
  <div className="ps-3 border border-dark">
    <div className="d-flex flex-column align-items-start">
      
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <p className="fs-2 fw-bold text-prim me-2">Email:</p>
          <p className="fs-2 fw-normal prim">Info@myvehiclereports.com</p> {/* Hardcoded email */}
        </div>
  
      </div>
      
      <div className="d-flex align-items-center justify-content-between w-100 mt-2">
        <div className="d-flex align-items-center">
          <p className="fs-2 fw-bold text-prim me-2">VIN:</p>
          <p className="fs-2 fw-normal prim">{vehicleData?.content.AamvaNmvtis.vehicleIdentification}</p>
        </div>
        {/* <button className="btn btn-primary" style={{ fontSize: '20px', padding: '10px 20px' }}>
          Download
        </button> */}
      </div>

    </div>
  </div>
</div>

{/* < br/><br/> */}


          <div className="row">
  {cardData.map((card, index) => (
    <div className="col-sm-12 col-md-6 col-lg-6 mb-3" key={index}> {/* 1 card on small, 2 on medium & large */}
      <div className="d-flex flex-column h-100 shadow card gap-2 p-3">
        <div className="d-flex justify-content-center align-items-center">
          <img src={card.imgSrc} alt="Card image cap" height={70} width={70} />
        </div>
        <h5 className="card-title fs-4 text-center">{card.title}</h5>
        {card.subtitle && (
          <h5 className="card-title cd2 fs-5 text-center text-prim">
            <span className="prim text-decoration-underline">
              {card.subtitle.split(" ")[0]}
            </span>{" "}
            {card.subtitle.split(" ").slice(1).join(" ")}
          </h5>
        )}
      </div>
    </div>
  ))}
</div>


          <div className="my-3 an">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">Vehicle Data</p>
            </div>
            <div className="overflowx mb-5">
              <table className="mytable">

                <tbody>
                  {VehicleDatafields.map((field, index) => (
                    <tr key={index}>
                      <td className="fw-semibold ">{field.label}</td>
                      <td>{getValue(vehicleData?.content.VinDecoder, field.key)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Image Container */}
            <div className="d-flex justify-content-center align-items-center my-3">
              {vehicleData.content.CarImages?.map((image, index) => (
                <div className="col-md-4 col-sm-4 mb-2 d-flex justify-content-center align-items-center" key={index}>
                  <img
                    height={400}
                    width={400}
                    src={image}
                    alt={`Vehicle Image ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={Speedo} alt="Card image cap" height={55} width={55} />
                  <span>Mileage</span>
                </div>
              </p>
            </div>
            <div className="overflowx mb-5">
              <table className="mytable">
                <tbody>
                  {MileageDatafields.map((field, index) => (
                    <tr key={index}>
                      <td className="fw-semibold ">{field.label}</td>
                      <td>{getValue2(vehicleData?.content.AamvaNmvtis.titles.current, field.key)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <p className="fs-6 text-start">
              <span className="text-muted font-italic">Note : </span>
              Please be aware that the estimated mileage provided is not the
              current mileage of the VIN-checked vehicle. Our system determines
              the average mileage by analyzing data from similar vehicles from
              same states.
            </p>
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={Accident} alt="Card image cap" height={55} width={55} />
                  <span>Accidents</span>
                </div>
              </p>
            </div>
            {/* If there is no accident trhenn below other wise above will be table or ask how i show the accidennt one  */}
            <p className="fs-6 text-start">
              <span className=" font-italic">
                Great news! There is no Accident Record for this car. This means
                that no accidents has been reported to NMVTIS by any of the
                state authorities.
              </span>
            </p>
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={TitleRecord} alt="Card image cap" height={55} width={55} />
                  <span>Title Record</span>
                </div>
              </p>
            </div>
            <div className="overflowx mb-5">
              <table className="mytable">
                <tbody>
                  {titrecDatafieldsDatafields.map((field, index) => (
                    <tr key={index}>
                      <td className="fw-semibold ">{field.label}</td>
                      <td>{getValue2(vehicleData?.content.AamvaNmvtis.titles.current, field.key)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>
          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={TitleRecord} alt="Card image cap" height={55} width={55} />
                  <span>History Record</span>
                </div>
              </p>
            </div>
            <div className="overflowx mb-5">
              <table className="mytable">
                <tbody>
                  {vehicleData?.content.AamvaNmvtis.titles.historic?.map((record, recordIndex) => (
                    <React.Fragment key={recordIndex}>
                      {histrecDatafieldsDatafields.map((field, fieldIndex) => (
                        <tr key={fieldIndex}>
                          <td className="fw-semibold">{field.label}</td>
                          <td>{getValue(record, field.key)}</td>
                        </tr>
                      ))}

                      <tr>
                        <td colSpan="2" style={{ height: '40px', border: 'none' }}></td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={Junk} alt="Card image cap" height={55} width={55} />
                  <span>Junk/Salvage Record</span>
                </div>
              </p>
            </div>
            {junkAndSalvage.length > 0 ? (
              <div className="overflowx mb-5">
                <table className="mytable">
                  <thead>
                    <tr>
                      <th>Entity Name</th>
                      <th>Location</th>
                      <th>Telephone</th>
                      <th>Obtained Date</th>
                      <th>Disposition</th>
                    </tr>
                  </thead>
                  <tbody>
                    {junkAndSalvage.map((record, index) => (
                      <tr key={index}>
                        <td>{record.entity.EntityName}</td>
                        <td>{`${record.entity.LocationCityName}, ${record.entity.LocationStateUSPostalServiceCode}`}</td>
                        <td>{record.entity.TelephoneNumberFullID}</td>
                        <td>{record.VehicleObtainedDate}</td>
                        <td>{record.VehicleDispositionText}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="fs-6 text-start">
                <span className="font-italic">
                  Great news! There is no Junk/Salvage Record for this car. This
                  means that no Junk/Salvage has been reported to NMVTIS by any of
                  the state authorities.
                </span>
              </p>
            )}
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={TotLossRec} alt="Card image cap" height={55} width={55} />
                  <span>Insurer "Total Loss" Records</span>
                </div>
              </p>
            </div>
            {insurers.length > 0 ? (
              <div className="overflowx mb-5">
                <table className="mytable">
                  <thead>
                    <tr>
                      <th>Entity Name</th>
                      <th>Location</th>
                      <th>Telephone</th>
                      <th>Obtained Date</th>
                      <th>Disposition</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insurers.map((record, index) => (
                      <tr key={index}>
                        <td>{record.entity.EntityName}</td>
                        <td>{`${record.entity.LocationCityName}, ${record.entity.LocationStateUSPostalServiceCode}`}</td>
                        <td>{record.entity.TelephoneNumberFullID}</td>
                        <td>{record.VehicleObtainedDate}</td>
                        <td>{record.VehicleDispositionText}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="fs-6 text-start">
                <span className="font-italic">
                  Great news! There is no Insurer "Total Loss" Record for this
                  car. This means that no Insurer "Total Loss" has been reported
                  to NMVTIS by any of the state authorities.
                </span>
              </p>
            )}
          </div>

          <div className="my-3">
            <div className="Content-heading ps-md-3 my-3">
              <p className="fs-1 fw-bold text-prim text-start">
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <img src={AutoSpecs} alt="Card image cap" height={55} width={55} />
                  <span>Auto Specs</span>
                </div>
              </p>
            </div>
          </div>

          <div>
            <div className="border border-black">
              <div className=" bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3 ">
                <FontAwesomeIcon
                  icon={faChartSimple}
                  size="2xl"
                  style={{ color: "white" }}
                  className=" bg-dark d-flex flex-column justify-content-start p-3"
                />
                <p className="fs-1 d-flex flex-column"> Vital </p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {VitalDatafields.map((field, index) => (
                      <tr key={index}>
                        <td className="fw-semibold ">{field.label}</td>
                        <td>{getValue(vehicleData?.content.VinDecoder, field.key)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <div className="border border-black  ">
              <div className=" bg-secondary bg-gradient d-flex  justify-content-start align-items-center gap-3 ">
                <FontAwesomeIcon
                  icon={faGear}
                  size="2xl"
                  style={{ color: "white" }}
                  className=" bg-dark d-flex  flex-column justify-content-start p-3"
                />
                <p className="fs-1 d-flex flex-column"> Vehicle Specifications</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {VehspecsDatafields.map((field, index) => (
                      <tr key={index}>
                        <td className="fw-semibold ">{field.label}</td>
                        <td>{getValue(vehicleData?.content.VinDecoder, field.key)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <div className="border border-black  ">
              <div className=" bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3 ">
                <FontAwesomeIcon
                  icon={faDroplet}
                  size="2xl"
                  style={{ color: "white" }}
                  className=" bg-dark d-flex justify-content-start p-3"
                />
                <p className="fs-1"> Measurements & colors</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {MeasureandColor.map((field, index) => {
                      const value = getValue(vehicleData?.content.VinDecoder, field.key);
                      return (
                        <tr key={index}>
                          <td className="fw-semibold">{field.label}</td>
                          <td>
                            {isBulletPointData(value) ? (
                              value.split('|').map((item, i) => (
                                <div key={i} className="bullet-point">
                                  &#8226; {item.trim()}
                                </div>
                              ))
                            ) : (
                              value
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <div className="border border-black  ">
              <div className=" bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3 ">
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  size="2xl"
                  style={{ color: "white" }}
                  className=" bg-dark d-flex justify-content-start p-3"
                />
                <p className="fs-1">Warranties</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {WarrantiesDataField.map((field, index) => (
                      <tr key={index}>
                        <td className="fw-semibold ">{field.label}</td>
                        <td>{getValue(vehicleData?.content.VinDecoder, field.key)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div>
            <div className="border border-black">
              <div className="bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3">
                <FontAwesomeIcon
                  icon={faUserShield}
                  size="2xl"
                  style={{ color: "white" }}
                  className="bg-dark d-flex justify-content-start p-3"
                />
                <p className="fs-1">Safety & Security</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {SASDataField.map((field, index) => {
                      const value = getValue(vehicleData?.content.VinDecoder, field.key);

                      // Replace 'std' with 'Standard' and 'opt' with 'Optional'
                      const formattedValue = value === "Std."
                        ? "Standard"
                        : value === "Opt."
                          ? "Optional"
                          : value;

                      return (
                        <tr key={index}>
                          <td className="fw-semibold">{field.label}</td>
                          <td>{formattedValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div>
            <div className="border border-black">
              <div className="bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3">
                <FontAwesomeIcon
                  icon={faGears}
                  size="2xl"
                  style={{ color: "white" }}
                  className="bg-dark d-flex justify-content-start p-3"
                />
                <p className="fs-1">Comfort</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {ComfortDataField.map((field, index) => {
                      const value = getValue(vehicleData?.content.VinDecoder, field.key);

                      // Replace 'std' with 'Standard' and 'opt' with 'Optional'
                      const formattedValue = value === "Std."
                        ? "Standard"
                        : value === "Opt."
                          ? "Optional"
                          : value;

                      return (
                        <tr key={index}>
                          <td className="fw-semibold">{field.label}</td>
                          <td>{formattedValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div>
            <div className="border border-black">
              <div className="bg-secondary bg-gradient d-flex justify-content-start align-items-center gap-3">
                <FontAwesomeIcon
                  icon={faImage}
                  size="2xl"
                  style={{ color: "white" }}
                  className="bg-dark d-flex justify-content-start p-3"
                />
                <p className="fs-1">Entertainment</p>
              </div>
              <div className="overflowx p-md-5">
                <table className="mytable">
                  <tbody>
                    {EntertainmentDatafields.map((field, index) => {
                      const value = getValue(vehicleData?.content.VinDecoder, field.key);

                      // Replace 'std' with 'Standard' and 'opt' with 'Optional'
                      const formattedValue = value === "Std."
                        ? "Standard"
                        : value === "Opt."
                          ? "Optional"
                          : value;

                      return (
                        <tr key={index}>
                          <td className="fw-semibold">{field.label}</td>
                          <td>{formattedValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          {/* With Logo Heading */}
          <div className="">
  <div className="Content-heading d-flex justify-content-start align-items-center gap-3 ">
    <FontAwesomeIcon
      icon={faMapLocationDot}
      size="2xl"
      className="d-flex justify-content-start p-3 prim"
    />
    <p className="fs-1 text-start prim">Location History</p>
  </div>

 
</div>
<div>
    <div >
      {Object.keys(vehicleData.content.CarInfo.locationHistoryArticles).length === 0 ? (
        <p className="text-center">No location history found</p>
      ) : (
        Object.keys(vehicleData.content.CarInfo.locationHistoryArticles).map((stateCode) => {
          const articles = vehicleData.content.CarInfo.locationHistoryArticles[stateCode];

          // Get the state name from the first article
          const stateName = articles[0].split(" ")[0]; // Extract the first word from the first article

          return (
            <div key={stateCode} className="my-4"> {/* Add some margin for spacing */}
              <h5 className=" fs-1 text-start prim fw-bold">{stateName}</h5> {/* Display the state name as a heading */}
              <ul className="list-unstyled">
                {articles.map((article, index) => (
                  <li key={index} className="my-2"> {/* Add some margin for spacing between list items */}
                    &#x2022; {article} {/* Use a bullet character for manual bullets */}
                  </li>
                ))}
              </ul>
            </div>
          );
        })
      )}
    </div>
  </div>




          <div className="">
            <div className="Content-heading  d-flex justify-content-start align-items-center gap-3 ">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size="2xl"
                className="  d-flex justify-content-start p-3 prim"
              />
              <p className="fs-1 text-start prim">NHTSA Recalls</p>
            </div>

            {/* First Recall */}
            <div>
              {sortedRecalls.map((recall, index) => (
                <div key={index}>
                  <div className="Content-heading ps-3">
                    <p className="fs-1 text-start prim">Recall #{index + 1}</p>
                  </div>
                  <div className="overflowx p-md-5">
                    <table className="mytable">
                      <tbody>
                        <tr>
                          <td className="fw-semibold">NHTSA Campaign #</td>
                          <td>{recall.CAMPNO || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Manufacturer Campaign #</td>
                          <td>{recall.MFGCAMPNO || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Manufacturer</td>
                          <td>{recall.MFGNAME || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Manufactured Between</td>
                          <td>{recall.BGMAN || "N/A"} - {recall.ENDMAN || "N/A"} </td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Recall Type</td>
                          <td>{recall.RCLTYPECD || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Potential Units Affected</td>
                          <td>{recall.POTAFF || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Owner Notification Date</td>
                          <td>{formatDate(recall.ODATE) || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Recall Initiator</td>
                          <td>{recall.INFLUENCED_BY || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Report Received Date</td>
                          <td>{formatDate(recall.RCDATE) || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Report Creation Date</td>
                          <td>{formatDate(recall.DATEA) || "N/A"}</td>
                        </tr>
                        {/* Add more fields as needed */}
                      </tbody>
                    </table>
                  </div>
                  <div className="Content-paragraph ps-3">
                    <p className="fs-4 text-start fw-bold">Defect Description:</p>
                    <br />
                    <p className="fs-5 text-justify">
                      {recall.DESC_DEFECT || "N/A"}
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="Content-paragraph ps-3">
                    <p className="fs-4 text-start fw-bold">Defect Consequences:</p>
                    <br />
                    <p className="fs-5 text-justify">
                      {recall.CONEQUENCE_DEFECT || "N/A"}
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="Content-paragraph ps-3">
                    <p className="fs-4 text-start fw-bold">Corrective Action:</p>
                    <br />
                    <p className="fs-5 text-justify">
                      {recall.CORRECTIVE_ACTION || "N/A"}
                    </p>
                  </div>
                  <br />
                  <br />
                  {/* <div className="Content-paragraph ps-3">
                    <p className="fs-4 text-start fw-bold">Notes:</p>
                    <br />
                    <p className="fs-5 text-justify">
                      {recall.NOTES || "N/A"}
                    </p>
                  </div> */}
                  <br />
                  <hr></hr>
                  <br />
                </div>
              ))}
            </div>
          </div>
          <div className="">
            <div className="Content-heading d-flex justify-content-start align-items-center gap-3">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size="2xl"
                className="d-flex justify-content-start p-3 prim"
              />
              <p className="fs-1 text-start prim">Consumer Complaints</p>
            </div>

            {/* Check if there are any complaints */}
            {complains.length > 0 ? (
              complains.map((complaint, index) => (
                <div key={index}>
                  <div className="Content-heading ps-3">
                    <p className="fs-1 text-start prim">Complaint #{index + 1}</p>
                  </div>
                  <div className="overflowx p-md-5">
                    <table className="mytable">
                      <tbody>
                        <tr>
                          <td className="fw-semibold">Complaint ID</td>
                          <td>{complaint.CMPLID || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">ODI Number</td>
                          <td>{complaint.ODINO || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Manufacturer</td>
                          <td>{complaint.MFR_NAME || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Make</td>
                          <td>{complaint.MAKETXT || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Model</td>
                          <td>{complaint.MODELTXT || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Year</td>
                          <td>{complaint.YEARTXT || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Crash Involved</td>
                          <td>{complaint.CRASH || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Fire Involved</td>
                          <td>{complaint.FIRE || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Injuries Reported</td>
                          <td>{complaint.INJURED || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Fatalities</td>
                          <td>{complaint.DEATHS || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Component</td>
                          <td>{complaint.COMPDESC || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">City</td>
                          <td>{complaint.CITY || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">State</td>
                          <td>{complaint.STATE || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">VIN</td>
                          <td>{complaint.VIN || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Incident Date</td>
                          <td>{complaint.FAILDATE || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-semibold">Report Date</td>
                          <td>{complaint.DATEA || "N/A"}</td>
                        </tr>
                        {/* Add more fields as needed */}
                      </tbody>
                    </table>
                  </div>
                  <div className="Content-paragraph ps-3">
                    <p className="fs-4 text-start fw-bold">Complaint Description:</p>
                    <br />
                    <p className="fs-5 text-justify">
                      {complaint.CDESCR || "N/A"}
                    </p>
                  </div>
                  <br />
                  <hr />
                  <br />
                </div>
              ))
            ) : (
              <div className="ps-3">
                <p className="fs-3 text-start prim">
                  Great news! There are no consumer complaints reported for this car.
                </p>
              </div>
            )}
          </div>

          <div className="">
            <div className="Content-heading d-flex justify-content-start align-items-center gap-3">
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                size="2xl"
                className="d-flex justify-content-start p-3 prim"
              />
              <p className="fs-1 text-start prim">Manufacturer Information</p>
            </div>
            <div className="Content-heading d-flex justify-content-center align-items-center gap-3">
              <div className="w-md-25">
                {/* <img src={manufacturer?.image} alt="logo" style={{ height: "300px" }} /> */}
                <div className="card-body">
                  <h3 className="card-title text-center fs-3">
                    <b>Brand:</b> {manufacturer?.carBrand}
                  </h3>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="" dangerouslySetInnerHTML={{ __html: manufacturer?.info }}></div>
          </div>

        </div>
        
      </div>
      <br/><br/>
      <hr/>
  <footer className="text-center py-4">
  <div className="d-flex justify-content-center align-items-center gap-3">
    <div className="Log">
      <img src={logo} alt="logo" style={{ height: "50px" }} />
    </div>
    <div>
      <p className="fs-5 text-prim fw-semibold">© All Rights Reserved {new Date().getFullYear()}</p>
    </div>
  </div>
  <div className="mt-3 ms-2"> {/* Add margin-start for left margin */}
    <h6 className="fw-bold">Disclaimer</h6>
    <p className="text-muted">This is a disclaimer regarding the information provided on this site.</p>
  </div>
  <div className="mt-2 ms-2"> {/* Add margin-start for left margin */}
    <h6 className="fw-bold">Refunds</h6>
    <p className="text-muted">Our refund policy can be found in the terms and conditions.</p>
  </div>
</footer>


    </>
  );
};

export default CustomReport;
