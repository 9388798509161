import React, { useState } from 'react';
import axios from 'axios';

const PaymentForm = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePayment = (e) => {
    e.preventDefault();

    const apiLoginID = '8TsSU7veZk9A';
    const clientKey = '3qd2Ad945WTzkvFTjSa39B4JySVUV8hm2KAscsVdgAGg49Psjv6j6e7c5W96WCSU';

    // Validate inputs
    if (!cardNumber || !expiry || !cvc || !amount) {
      setError('All fields are mandatory. Please provide all required information.');
      return;
    }

    // Split expiry into month and year
    const [month, year] = expiry.split('/').map((value) => value.trim());

    if (!month || !year || isNaN(month) || isNaN(year)) {
      setError('Expiry date must be in the format MM/YY.');
      return;
    }

    const cardData = {
      cardNumber: cardNumber.trim(),
      month: month.padStart(2, '0'), // Ensure month is two digits
      year: year.length === 2 ? '20' + year : year, // Ensure year is four digits
      cardCode: cvc.trim(),
    };

    // Check if Accept.js is available
    if (!window.Accept || !window.Accept.dispatchData) {
      setError('Accept.js library is not loaded. Please check the script inclusion.');
      return;
    }

    // Tokenize the card details using Accept.js
    window.Accept.dispatchData(
      {
        apiLoginID,
        clientKey,
        cardData,
      },
      responseHandler
    );
  };

  const responseHandler = (response) => {
    // Log the response to inspect the data returned from Accept.js
    console.log('Response from Accept.js:', response);

    if (response.messages.resultCode === 'Error') {
      setError(response.messages.message[0]?.text || 'Error processing your payment.');
      return;
    }

    // Check if opaqueData is present
    if (!response.opaqueData || !response.opaqueData.dataDescriptor || !response.opaqueData.dataValue) {
      setError('Opaque data is missing from the response. Please try again.');
      return;
    }

    // Proceed to payment processing
    const paymentData = {
      opaqueData: {
        dataDescriptor: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
      },
      amount,
    };

    processPayment(paymentData);
  };

  const processPayment = async (paymentData) => {
    try {
      const res = await axios.post('http://localhost:5000/api/payment', paymentData); // Your server-side payment endpoint
      if (res.data.success) {
        setSuccess('Payment successful!');
        setError('');
      } else {
        setError('Payment failed. Please try again.');
      }
    } catch (error) {
      setError('Error processing payment. Please try again later.');
    }
  };

  return (
    <form onSubmit={handlePayment}>
      <h2>Payment Form</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <div>
        <label>Card Number</label>
        <input
          type="text"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          placeholder="4111111111111111"
          required
        />
      </div>
      <div>
        <label>Expiry (MM/YY)</label>
        <input
          type="text"
          value={expiry}
          onChange={(e) => setExpiry(e.target.value)}
          placeholder="12/25"
          required
        />
      </div>
      <div>
        <label>CVC</label>
        <input
          type="text"
          value={cvc}
          onChange={(e) => setCvc(e.target.value)}
          placeholder="123"
          required
        />
      </div>
      <div>
        <label>Amount</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="5"
          required
        />
      </div>
      <button type="submit">Pay Now</button>
    </form>
  );
};

export default PaymentForm;
