// import React from "react";
// import GlobalStyles from 'styles/GlobalStyles';
// import { css } from "styled-components/macro"; //eslint-disable-line

// /*
//  * This is the entry point component of this project. You can change the below exported default App component to any of
//  * the prebuilt landing page components by uncommenting their import and export lines respectively.
//  * See one of the landing page components to better understand how to import and render different components (Always
//  * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
//  * disable the animation by using the disabled prop.
//  *
//  * The App component below is using React router to render the landing page that you see on the live demo website
//  * and the component previews.
//  *
//  */

// /* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// // import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// /*
//  * Hero section is the top most section on the page. It contains the header as well.
//  * So you dont need to import headers
//  * separately
//  */

// // import Hero from "components/hero/TwoColumnWithVideo.js";
// // import Hero from "components/hero/TwoColumnWithInput.js";
// // import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// // import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// // import Hero from "components/hero/FullWidthWithImage.js";
// // import Hero from "components/hero/BackgroundAsImage.js";
// // import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// // import Features from "components/features/ThreeColSimple.js";
// // import Features from "components/features/ThreeColWithSideImage.js";
// // import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// // import Features from "components/features/VerticalWithAlternateImageAndText.js";
// // import Features from "components/features/DashedBorderSixFeatures";
// // import MainFeature from "components/features/TwoColWithButton.js";
// // import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// // import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// // import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// // import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// // import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// // import Pricing from "components/pricing/ThreePlans.js";
// // import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// // import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// // import SliderCard from "components/cards/ThreeColSlider.js";
// // import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// // import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// // import TabGrid from "components/cards/TabCardGrid.js";

// // import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// // import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// // import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// // import Blog from "components/blogs/GridWithFeaturedPost.js";

// // import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// // import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// // import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// // import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// // import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// // import FAQ from "components/faqs/SimpleWithSideImage.js";
// // import FAQ from "components/faqs/SingleCol.js";
// // import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// //import ContactUsForm from "components/forms/SimpleContactUs.js";
// // import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// // import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
// //
// // import GetStarted from "components/cta/GetStarted.js";
// // import GetStarted from "components/cta/GetStartedLight.js";
// // import DownloadApp from "components/cta/DownloadApp.js";

// // import Footer from "components/footers/SimpleFiveColumn.js";
// // import Footer from "components/footers/FiveColumnWithInputForm.js";
// // import Footer from "components/footers/FiveColumnWithBackground.js";
// // import Footer from "components/footers/FiveColumnDark.js";
// // import Footer from "components/footers/MiniCenteredFooter.js";

// /* Ready Made Pages (from demos folder) */
// // import EventLandingPage from "demos/EventLandingPage.js";
// // import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// // import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import NextPage from "demos/NextPage";
// //import SaaSProductLandingPage from "SaaSProductLandingPage.js";

// //import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// // import SaaSProductLandingPage from "SaaSProductLandingPage.js";

// // import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// // import ServiceLandingPage from "demos/ServiceLandingPage.js";
// // import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

// /* Inner Pages */
// // import LoginPage from "pages/Login.js";
// // import SignupPage from "pages/Signup.js";
// // import PricingPage from "pages/Pricing.js";
// // import AboutUsPage from "pages/AboutUs.js";
// // import ContactUsPage from "pages/ContactUs.js";
// // import BlogIndexPage from "pages/BlogIndex.js";
// // import TermsOfServicePage from "pages/TermsOfService.js";
// // import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

// import ComponentRenderer from "ComponentRenderer.js";
// import MainLandingPage from "MainLandingPage.js";
// import ThankYouPage from "ThankYouPage.js";
// import VehicleHistory from "components/checkvin/Reporthistory";
// import VehicleHistory1 from "components/checkvin/Reporthistorywithauction";

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// export default function App() {
//   // If you want to disable the animation just use the disabled `prop` like below on your page's component
//   // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


// return (
//  <>
//       <GlobalStyles />
//      <Router>
//        <Routes>
//          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
//           <Route path="/components/:type/:name" element={<ComponentRenderer />} />
//           <Route path="/thank-you" element={<ThankYouPage />} />
//           <Route path="/" element={<SaaSProductLandingPage />} />
//           <Route path="/main" element={<MainLandingPage/>}/>
//           <Route path="/report/:vin" element={<VehicleHistory/>}/>
//           <Route path="/reportauction/:vin" element={<VehicleHistory/>}/>
//           <Route path="/vinreport/:id" element={<NextPage/>}/>

//         </Routes>
//      </Router>
//    </>
//   );
// }

// // export default EventLandingPage;
// // export default HotelTravelLandingPage;
// // export default AgencyLandingPage;
// // export default SaaSProductLandingPage;
// // export default RestaurantLandingPage;
// // export default ServiceLandingPage;
// // export default HostingCloudLandingPage;

// // export default LoginPage;
// // export default SignupPage;
// // export default PricingPage;
// // export default AboutUsPage;
// // export default ContactUsPage;
// // export default BlogIndexPage;
// // export default TermsOfServicePage;
// // export default PrivacyPolicyPage;

// // export default MainLandingPage;


import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import SaaSProductLandingPage from 'demos/SaaSProductLandingPage.js';
import ComponentRenderer from 'ComponentRenderer.js';
import MainLandingPage from 'MainLandingPage.js';
import ThankYouPage from 'ThankYouPage.js';
import VehicleHistory from 'components/checkvin/Reporthistory';
import VehicleHistory1 from 'components/checkvin/Reporthistorywithauction';
import NextPage from 'demos/NextPage';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import VinReport from 'components/checkvin/Reports';
import AesEncryption from 'components/checkvin/Reportjson';
import Price from 'demos/Price';
import Contact from 'demos/Contact';
import CustomReport from 'components/checkvin/CustomReport';
import PaymentForm from 'components/checkvin/testing';
//import PDFGENNRATOR from 'components/checkvin/Pdfgen';
//import vehicleData from "./aaa.json";


export default function App() {
  const currentPath = window.location.pathname;
  console.log(currentPath);
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/" element={<SaaSProductLandingPage />} />
          <Route path="/main" element={<MainLandingPage />} />
          <Route path="/report/:vin" element={<VehicleHistory />} />
          <Route path="/reportauction/:vin" element={<VehicleHistory1 />} />
          <Route path="/vinreport/:id" element={<NextPage />} />
          <Route path="/about" element = {<PrivacyPolicy/>}/>
          <Route path="/completevinreport/:vin" element = {<VinReport />}/>
          <Route path="/a" element = {<AesEncryption/>}/>
          <Route path="/price" element = {<Price/>}/>
          <Route path="/contactus" element = {< Contact />} />
          <Route path="/test" element = {< PaymentForm />} />
     
          
        </Routes>
      {/* {!currentPath.startsWith('/completevinreport/') && <TawkToChat />} */}
      </Router>
      {/* {location.pathname !== '/completevinreport/:vin' && <TawkToChat />} */}
    </>
  );
}

