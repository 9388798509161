import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const AesEncryption = () => {
  const [text, setText] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const [decryptedText, setDecryptedText] = useState('');
  const [key, setKey] = useState('');

  const handleEncrypt = () => {
    const ciphertext = CryptoJS.AES.encrypt(text, key).toString();
    const base64Ciphertext = btoa(ciphertext).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    setEncryptedText(base64Ciphertext);
  };

  const handleDecrypt = () => {
    const base64Ciphertext = encryptedText.replace(/-/g, '+').replace(/_/g, '/');
    const ciphertext = atob(base64Ciphertext);
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    setDecryptedText(originalText);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>AES Encryption and Decryption</h2>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Enter text:
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', width: '100%' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Enter key:
          <input
            type="text"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', width: '100%' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={handleEncrypt} style={{ padding: '10px 20px', marginRight: '10px' }}>
          Encrypt
        </button>
        <button onClick={handleDecrypt} style={{ padding: '10px 20px' }}>
          Decrypt
        </button>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <h3>Encrypted Text</h3>
        <textarea
          value={encryptedText}
          readOnly
          rows="4"
          style={{ width: '100%', padding: '10px' }}
        ></textarea>
      </div>
      <div>
        <h3>Decrypted Text</h3>
        <textarea
          value={decryptedText}
          readOnly
          rows="4"
          style={{ width: '100%', padding: '10px' }}
        ></textarea>
      </div>
    </div>
  );
};

export default AesEncryption;
