import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import React from "react";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "../components/headers/light.js";
import Report from "components/checkvin/Report.js";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
  
    return (
      <AnimationRevealPage>
        <Header  roundedHeaderButton={true}  />
        <Report  heading={<>Car Details By <HighlightedText>My Vehicle Report</HighlightedText></>} />
        
        {/* <Footer /> */}
    </AnimationRevealPage>
  );
}