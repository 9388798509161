import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/resorce.png";
import BackgroundImage from "../../images/car.png"; // Import your background image

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-10/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-center content-center`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-primary-400 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-primary-400 xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-2xl text-center mx-auto content-center flex justify-center`} 

  .input-container {
    ${tw`relative w-full flex items-center m-2`}
  }

  input {
    ${tw`w-full pl-8 py-4 sm:py-5 rounded-full border-2 font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500 pr-20`} // Add padding-right to avoid overlapping with the button
  }

  button {
    ${tw`   absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-1 my-2 sm:my-1  rounded-full py-4 flex items-center justify-center w-32 sm:w-40  sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`},
    
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-96 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`items-center mt-12 lg:mt-20  flex flex-col `} // Flexbox used to center content
  p {
    ${tw`uppercase text-sm lg:text-sm tracking-wider font-bold text-gray-200`}
  }
  img {
    ${tw`lg:ml-40 ml-0 mt-4 w-full lg:pr-16 xl:pr-32`} // Removed opacity
  }
`;


const StyledHeader = styled.div`
  ${tw`relative`}
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1024px) {
    background-attachment: scroll; // Use scroll on smaller screens to avoid issues
  }

  @media (prefers-reduced-motion: reduce) {
    background-attachment: scroll; // Use scroll if user prefers reduced motion
  }
`;

export default ({ roundedHeaderButton }) => {
  const [vin, setVin] = useState(""); // State to manage VIN input

  return (
    <>
     
        <Header roundedHeaderButton={roundedHeaderButton} />
     
        <StyledHeader> {/* Wrap your Header component with StyledHeader */} 
      <Container>

        <TwoColumn>
          <LeftColumn>
            <Heading>
              Vehicle History Reports That  <br/>
              <span tw="text-primary-500"> You Can Rely On.</span>
            </Heading>
            <Paragraph>
              If the used vehicle you're looking in has been in an accident, we'll find out.
            </Paragraph>
            <Actions>
      <div className="input-container">
        <input 
          type="text" 
          placeholder="Enter the Vin Number" 
          value={vin}
          onChange={(e) => setVin(e.target.value)} // Update state on input change
        />
        <Link to={`/vinreport/${vin}`} style={{ textDecoration: 'none' }}>
          <button onClick={() => vin && setVin(vin)}>Check Now</button> {/* Link wraps the button */}
        </Link>
      </div>
    </Actions>
            {/* <CustomersLogoStrip>
              <p>Our Resources</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
          {/* <RightColumn> */}
            {/* <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer> */}
          {/* </RightColumn> */}
        </TwoColumn>
        <DecoratorBlob1 />
 
      </Container>
      </StyledHeader>
 
    </>
  );
};