import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Header from "../components/headers/light.js";
import TawkToChat from "TawkToChat.js";



export default () => {

  return (
    <AnimationRevealPage>
    <Header />
    <Pricing />
    <Footer />
   < TawkToChat />
  </AnimationRevealPage>
  );
}
