import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/vin-decode.jpg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import VinLookupReport from "components/checkvin/lookup";
import TawkToChat from "TawkToChat";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />

      <FeatureWithSteps
      />
      <VinLookupReport/>
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our VIN reports are professional and accurate.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
     
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "I was hesitant at first, but the VIN testing service exceeded my expectations! The report was detailed and easy to understand. It gave me peace of mind when purchasing my used car. Highly recommend!",
            customerName: "Peter Hale",
            customerTitle: "*actual image hidden due to privacy concerns"
          },
           {
            stars: 5,
            profileImageSrc:
              "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?t=st=1721310027~exp=1721313627~hmac=65e533f5bc3ed4f7e944c4169b491ad83e603619e69eab2c47b362c9e66d6f4f&w=1060",
            heading: "Lifesaver to say the least",
            quote:
              "I couldn’t believe how easy it was to get a detailed VIN report! The insights helped me avoid a potential lemon. This service is a must for anyone buying a used car. I’m very impressed!",
            customerName: "Alex Ramsey",
            customerTitle: "*actual image hidden due to privacy concerns"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Accuracy and Speed of Reports",
            quote:
              "Fantastic experience! The VIN check was quick and thorough. I found out crucial information about the vehicle's history that helped me make an informed decision. Will definitely use this service again",
            customerName: "Josh Cuppy",
            customerTitle: "*actual image hidden due to privacy concerns"
          }
        ]}
      />
  <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Do you have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is a VIN history report?",
            answer:
              "A VIN history report provides detailed information about a vehicle's past, including accidents, title changes, and ownership history. This helps you make an informed decision when purchasing a used car."
          },
          {
            question: "How can I get a VIN history report?",
            answer:
              "Simply enter the Vehicle Identification Number (VIN) of the car you are interested in on our website, and you will receive a detailed report instantly."
          },
          {
            question: "Why is a VIN history report important?",
            answer:
              "A VIN history report is crucial for understanding the true condition and history of a used vehicle. It helps you avoid cars with hidden issues, ensuring a safer and smarter purchase."
          },
          {
            question: "What information is included in a VIN history report?",
            answer:
              "Our VIN history reports include accident history, title information, odometer readings, ownership details, and more. This comprehensive data gives you a clear picture of the vehicle's past."
          },
          {
            question: "Can I check the history of any vehicle using a VIN?",
            answer:
              "Yes, you can check the history of any vehicle with a valid VIN, including cars, trucks, motorcycles, and more."
          },
          {
            question: "Are payments refundable?",
            answer:
              "Please note that all payments for our vehicle history reports are non-refundable. We encourage you to review the sample report before making a purchase."
          }
        ]}
      />
     <Footer />
     <TawkToChat />
    </AnimationRevealPage>
 
  );
}
