import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import BackgroundImage from "../../images/car.png"; // Import your background image

const Container = tw.div`bg-gray-800 text-white min-h-screen flex flex-col items-center justify-center p-4`;

// Heading and text styling
const Heading = tw.h2`text-3xl sm:text-4xl lg:text-5xl  text-center mb-8`;
const HighlightedText = tw.span`text-primary-500`;
const Text = tw.div`text-center relative lg:w-6/12`;

const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  max-width: screen-xl;
  margin: 0 auto;
`;

// Card styling with green border
const Card = styled.div`
  ${tw`bg-none p-6 mb-5 rounded-lg shadow-lg flex flex-col items-start text-black`}
  border: 4px solid #123d63;
  border-radius: 30px;
  padding: 2rem;
  background-color: white;
  drop-shadow: 0 10px 100px rgba(0, 0, 0, 0.4);
`; 

// Card heading and list styling
const CardHeading = tw.h3`lg:text-2xl  mb-4 text-primary-500`;
const List = tw.ul`list-none pl-0`;
const ListItem = styled.li`
  ${tw`mb-2 flex items-start`}
  &:before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const VinLookupReport = () => {
  return (
    <Container style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundOpacity: '20%' }}>
      <Heading>
        What is Included in <HighlightedText>VIN Lookup Reports?</HighlightedText>
      </Heading>
      <Text>
        Access high-quality reports affordably through a VIN Lookup from Vehicles Reports.
        Whether you possess a VIN or license plate number, explore detailed auto specs,
        title records, accident history, pricing, and more. Delve into Vehicles Reports'
        extensive database for comprehensive insights, covering:
      </Text>
      <br/>
      <GridContainer>
        <div>

        <Card>
          <CardHeading>Title Records</CardHeading>
          <List>
            <ListItem>Mileage</ListItem>
            <ListItem>Odometer Records</ListItem>
            <ListItem>Sales History</ListItem>
            <ListItem>Title Brand (Problem Check)</ListItem>
            <ListItem>Title Issues</ListItem>
          </List>
        </Card>
        <Card>
          <CardHeading>Automobile Specs</CardHeading>
          <List>
            <ListItem>Engine Type</ListItem>
            <ListItem>Transmission Type</ListItem>
            <ListItem>Standard Specifications</ListItem>
            <ListItem>Optional Specifications</ListItem>
            <ListItem>Standard Generic Equipment</ListItem>
            <ListItem>Entertainment and Technology</ListItem>
            <ListItem>Exterior and Interior Features</ListItem>
            <ListItem>Optional Generic Equipment</ListItem>
            <ListItem>OEM Options</ListItem>
            <ListItem>Exterior/Interior Colors</ListItem>
            <ListItem>Safety Equipment</ListItem>
            <ListItem>EPA MPG (Fuel Economy)</ListItem>
          </List>
        </Card>
        </div>
     
        <Card>
          <CardHeading>Problem Checks</CardHeading>
          <List>
            <ListItem>Junk and Salvage</ListItem>
            <ListItem>Total Loss</ListItem>
            <ListItem>Recalls and Defects</ListItem>
            <ListItem>Flood Damage</ListItem>
            <ListItem>Theft and Vandalism</ListItem>
            <ListItem>Fire Damage</ListItem>
            <ListItem>Hail Damage</ListItem>
            <ListItem>Accidents</ListItem>
            <ListItem>Clunkers Repair</ListItem>
            <ListItem>Rebuild Records</ListItem>
            <ListItem>Reconstructed</ListItem>
            <ListItem>Saltwater Damage</ListItem>
            <ListItem>Crashed</ListItem>
            <ListItem>Hazardous</ListItem>
            <ListItem>Dismantled</ListItem>
            <ListItem>Refurbished</ListItem>
            <ListItem>Test Vehicle</ListItem>
            <ListItem>Collision Records</ListItem>
            <ListItem>Warranty Returns</ListItem>
            <ListItem>Gray Market</ListItem>
            <ListItem>Remanufactured</ListItem>
            <ListItem>Police or Taxi</ListItem>
            <ListItem>Former Rental</ListItem>
            <ListItem>Lien Records</ListItem>
            <ListItem>And More!</ListItem>
          </List>
        </Card>
        <div>
        <Card>
          <CardHeading>Pricing</CardHeading>
          <List>
            <ListItem>Trade-In</ListItem>
            <ListItem>Retail</ListItem>
            <ListItem>Private Party</ListItem>
            <ListItem>Residuals</ListItem>
          </List>
        </Card>
      
        <Card>
          <CardHeading>Additional Information</CardHeading>
          <List>
            <ListItem>Sales History</ListItem>
            <ListItem>Manufacturer Information</ListItem>
            <ListItem>Awards & Accolades</ListItem>
            <ListItem>NHTSA Recalls</ListItem>
            <ListItem>NHTSA Crash Test Ratings</ListItem>
            <ListItem>Location History</ListItem>
            <ListItem>Performance</ListItem>
            <ListItem>Safety & Security</ListItem>
            <ListItem>Warranties</ListItem>
          </List>
        </Card>
        </div>
   
      </GridContainer>
    </Container>
  );
};

export default VinLookupReport;
